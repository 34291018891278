<template>
    <div class="navContent" :id="navCom">
        <div :class="navClass">
            <div class="main">
            	<div v-if="navClass=='topNav'" style="display: flex;align-items: center;">
					<img style="margin-right: 20px;"
	                    @click="toPage('/')"
	                    src="../assets/PC/navigation_ic_whitelogo.png"
	                    alt="logo"
	                    class="logo"
	                />
					<div style="border-left: 1px solid #fff;height: 20px;"></div>
					<div class="wenan" style="color: #fff;margin-left:15px;" >
						服务热线：400-666-3721
					</div>
				</div>
                <!-- 手机版展示列表按钮 -->
                <!--<img v-if="navClass=='topNav'"
                    @click="toPage('/')"
                    src="../assets/PC/navigation_ic_whitelogo.png"
                    alt="logo"
                    class="logo"
                />-->
                <img
                    v-if="navClass!='topNav'"
                    @click="toPage('/')"
                    src="../assets/PC/about_img_logo.png"
                    alt="logo"
                    class="logo"
                />


                <!-- 导航 -->
                <nav class="nav" >
                    <!-- <el-tabs v-model="activeName" @mouseenter="enter('0')" @mouseleave="leave('0')" @tab-click="handleClick">
                        <el-tab-pane label="简介" name="first" @mouseenter="enter('0')" @mouseleave="leave('0')"></el-tab-pane>
                        <el-tab-pane label="业务" name="second" @mouseenter="enter('1')" @mouseleave="leave('1')"></el-tab-pane>
                        <el-tab-pane label="员工" name="third" @mouseenter="enter('2')" @mouseleave="leave('2')"></el-tab-pane>
                        <el-tab-pane label="企业责任" name="fourth" @mouseenter="enter('3')" @mouseleave="leave('3')"></el-tab-pane>
                    </el-tabs> -->
                    <div class="nav_tab">
                    	<a target="_blank" href="https://online.uyl.cn/" style="position: fixed;right: 20px;top: 79%;"><img style="width: 56px;" src="../assets/ic_blueonline.png" /></a>
                        <ul>
                            <li :class="activeName=='first'?'active':''" @click="handleClick('first')" @mouseenter="enter('0')" @mouseleave="leave('0')">简介</li>
                            <li :class="activeName=='second'?'active':''" @click="handleClick('second')" @mouseenter="enter('1')" @mouseleave="leave('1')">业务</li>
                            <li :class="activeName=='third'?'active':''" @click="handleClick('third')" @mouseenter="enter('2')" @mouseleave="leave('2')">员工</li>
                            <li :class="activeName=='fourth'?'active':''" @click="handleClick('fourth')" @mouseenter="enter('3')" @mouseleave="leave('3')">企业责任</li>
                        </ul>
                    </div>
                </nav>
                <img v-if="navClass=='topNav'&&!navHide" src="../assets/H5/ic_whitelist.png" class="H5List" alt="" @click="navH5Hide()">
                <img v-if="navClass!='topNav'&&!navHide" src="../assets/H5/ic_blacklist.png" class="H5List" alt="" @click="navH5Hide()">
                <img v-if="navHide" src="../assets/H5/ic_closed.png" class="H5List" alt="" @click="navH5Hide()">
            </div>
            <!-- 弹框选择 -->
            <div class="navListBGC" @click="navHide=false" v-if="navHide">
                <div class="navList" @mouseleave="navListMou()">
                    <div class="main">
                        <ul v-if="activeNameHide=='first'" >
                            <li @click="navClick(0)" :class="navtype==0?'active':''">公司简介</li>
                            <li @click="navClick(1)" :class="navtype==1?'active':''">使命及愿景</li>
                            <li @click="navClick(2)" :class="navtype==2?'active':''">发展历程</li>
                            <li @click="navClick(3)" :class="navtype==3?'active':''">业务架构</li>
                            <li @click="navClick(4)" :class="navtype==4?'active':''">企业文化</li>
                            <li @click="navClick(5)" :class="navtype==5?'active':''">办公地点</li>
                        </ul>
                        <ul style="width: 270px;" v-if="activeNameHide=='second'" >
                            <li @click="navClicks('/work',0)">面向用户</li>
                            <li @click="navClicks('/work',1)">面向政企</li>
                            <li @click="navClicks('/work',2)">依蓝云定制</li>
                        </ul>
                        <ul style="width: 180px;" v-if="activeNameHide=='third'" >
                            <li @click="navClicks('/staff',0)">人才发展</li>
                            <li @click="navClicks('/staff',1)">依蓝云学院</li>
                            <li @click="navClicks('/staff',2)">工作环境</li>
                            <li @click="navClicks('/staff',3)">员工活动</li>
                        </ul>
                        <ul style="width: 90px;    " v-if="activeNameHide=='fourth'" >
                            <li style="text-align: right;" @click="navClicks('/enterprise',0)">全球战疫</li>
                            <li style="text-align: right;" @click="navClicks('/enterprise',1)">助力高考</li>
                            <li style="text-align: right;" @click="navClicks('/enterprise',2)">践行承诺</li>
                        </ul>
                        <!-- <div @click="navHide=false" style="position: fixed;left: 0;top: 480px;width: 100%;height: 401px;"></div> -->
                    </div>
                    <div class="H5main">
                        <ul>
                            <li @click="toPage('/introduce')">简介</li>
                            <li @click="toPage('/work')">业务</li> 
                            <li @click="toPage('/staff')">员工</li>
                            <li @click="toPage('/enterprise')">企业责任</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <router-view :scrollTop="scrollNumber"></router-view>

        <div class="footer" :style="footStyleMin">
            <div class="uss"> 
                <ul>
                    <li>关注我们</li>
                    <li>
                        <el-popover
                            placement="top"
                            width="150"
                            trigger="hover">
                            <img style="width:150px;height:150px;" src="../assets/PC/duty_img_banner2.jpg" alt="">
                            <el-button slot="reference">
                                <img src="../assets/PC/ic_wechat.png" alt="">
                                <img class="er_img" src="../assets/H5/ic_graywechat.png" alt="">
                                </el-button>
                        </el-popover>
                    </li>
                    <li>
                        <!-- <el-popover
                            placement="top"
                            width="150"
                            trigger="hover"> -->
                            <!-- <img style="width:150px;height:150px;" src="https://img1.baidu.com/it/u=430278564,3364937166&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" alt=""> -->
                            <el-button slot="reference">
                                <a href="https://weibo.com/wwwuylcn" target="_blank"><img src="../assets/PC/ic_weibo.png" alt=""></a>
                                <a href="https://weibo.com/wwwuylcn"><img class="er_img" src="../assets/H5/ic_grayweibo.png" alt=""></a>
                            </el-button>
                        <!-- </el-popover> -->
                    </li>
                    <li>
                        <el-popover
                            placement="top"
                            width="150"
                            trigger="hover">
                            <img style="width:150px;height:150px;" src="../assets/PC/0c475fc6-5ff2-4b8a-b688-45bc7218c60d.png" alt="">
                            <el-button slot="reference">
                                <img src="../assets/PC/ic_douyin.png" alt="">
                                <img class="er_img" src="../assets/H5/ic_graydouyin.png" alt="">
                                </el-button>
                        </el-popover>
                    </li>
                    <span class="span" style="color: #fff;float: right;font-size: 16px;">服务热线：400-666-3721</span>
                </ul>
            </div>
            <div class="footLogo">
                <div class="main">
                    <ul>
                        <li>联系我们</li>
                        <li>法律信息</li>
                        <li @click="service(5)">售后服务</li>
                        <li @click="service(6)">服务协议</li>
                        <li @click="service(7)">商务咨询</li>  
                        <li @click="service(8)">隐私政策</li>
                    </ul>
                    <img src="../assets/PC/about_img_logo.png" class="foorImg" alt="">
                </div>
                <div class="footCon">
                <a  target="_blank"  href="https://online.uyl.cn/" style="position: fixed;right: 0.35rem;top: 557px;"><img style="width: 1.2rem;" src="../assets/ic_grayonline.png" /></a>
                	
                    <el-collapse v-model="activeNames" @change="handleChange">
                        <el-collapse-item title="联系我们" name="1">
                            <div @click="service(5)">-售后服务</div>
                            <div @click="service(7)">-商务咨询</div>
                        </el-collapse-item>
                        <el-collapse-item title="法律信息" name="2">
                            <div @click="service(6)">-服务协议</div>
                            <div @click="service(8)">-隐私政策</div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
            <div class="foot">
                <div class="main">
                    <ul>
                        <li @click="service(9)">法律声明</li>
                        <li @click="service(10)">明德准则</li>
                        <li @click="toPage('/map')">网站地图</li>
                        <li style="cursor: auto;">鲁ICP备10012159号-7</li>
                    </ul>
                    <p class="navPC">Copyright © 2006 - 2022 Yilan. All Rights Reserved. 依蓝云公司 版权所有
                        <span class="navPC bot2">公安部联网备案号：37021302000338</span>
                    </p>
                    <p class="navH5">鲁ICP备10012159号-7<br>Copyright © 2006 - 2022 Yilan. All Rights Reserved. <br>依蓝云公司 版权所有
                        <span class="navH5 bot3">公安部联网备案号：37021302000338</span>
                    </p>
                </div>
            </div>
        </div>

        <div v-if="backTop" @click="scrollTopMet()" class="back_Top" style="visibility: visible;">
			<p>TOP</p>
		</div>
    </div>
</template> 
<script>
export default {
    inject:['reload'],
    data() {
        return {
            backTop: false,//到顶部按钮
            activeName: '',//tab切换
            activeNameHide: '',//tab列表切换
            scrollNumber:"",//滚动条到顶部距离
            navClass:'topNav',//顶部导航样式
            navHide:false,//首页导航
            activeNames: [],//H5 底部展开
            navCom:'navComActive',
            navtype:0,
            navtype2:'',
            navtype3:'',
            navtype4:'',
            scrollTopNumber:[580,1070,1546,2292,3278,3978],
            scrollNumberH5L:'',
            footStyleMin:'', //底部首页最小宽度
        };
    },
    watch:{
        "$route":"getPath" 
    },
    mounted () {
        this.getPath()
        window.addEventListener('scroll', this.menu)
        this.isTabletCss()
    },
    methods: {
        // 判断是否平板
        isTabletCss(){
            var ua = navigator.userAgent,
            isWindowsPhone = /(?:Windows Phone)/.test(ua),
            isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
            isAndroid = /(?:Android)/.test(ua),
            isFireFox = /(?:Firefox)/.test(ua),
            isChrome = /(?:Chrome|CriOS)/.test(ua),
            isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
            isPhone = /(?:iPhone)/.test(ua) && !isTablet,
            isPc = !isPhone && !isAndroid && !isSymbian;
            if(isTablet){
                return true
            }else{
                return false
            }
        },
        // 点击到顶部
        scrollTopMet(){
            document.documentElement.scrollTop=0
            document.body.scrollTop=0
            window.scrollTo(0, 0)
        },
        enter(type){
            this.navClass='topNav navActive'
            this.navtype=''
            if(type=='0'){
                this.activeNameHide='first'
            }else if(type=='1'){
                this.activeNameHide='second'
            }else if(type=='2'){
                this.activeNameHide='third'
            }else{
                this.activeNameHide='fourth'
            }
            this.navHide=true
        },
        navListMou(){
            this.navHide=false;
        },
        leave(type){
            let path = this.$route.path;    //或得当前路径

        },
        navClick(index){
            document.documentElement.scrollTop=0
            this.$router.push({
                path:'/introduce',
                query:{
                    herfIndex:index
                }
            });
            this.navHide=false
            this.reload();
        },
        navClicks(path,index){
            document.documentElement.scrollTop=0
                this.$router.push({
                    path:path,
                    query:{
                        herfIndex:index
                    }
                });
            this.navHide=false
            this.reload();
        },
        // 跳转服务相关
        service(type){
            this.$router.push({
                path:'/service',
                query:{
                    herfIndex:type
                }
            })
        },
        // 监听路径
        getPath(){
            this.navCom='navComActive'
            // this.activeName=''
            let path = this.$route.path;    //或得当前路径
            // console.log(path)
            if(path=='/'){
                this.navClass='topNav'
                this.footStyleMin='min-width: 1100px;'
            }else{
                this.navClass='topNav navActive'
                this.footStyleMin=''
            }
            if(path=='/introduce'){
                this.activeName='first'
            }else if(path=='/work'){
                this.activeName='second'
            }else if(path=='/staff'){
                this.activeName='third'
            }else if(path=='/enterprise'){
                this.activeName='fourth'
            }else{
                this.activeName=''
            }
        },
        // 顶部导航切换
        handleClick(type) {
            this.activeName=type;
            // console.log(this.activeName);
            // console.log(tab, event);
            if(this.activeName=='first'){
                if(this.navHide){
                    let path = this.$route.path;  
                    if(path=='/'){
                        this.navClass='topNav'
                    }else{
                        this.navClass='topNav navActive'
                    }
                }else{
                    this.navClass='topNav navActive'
                }
                this.navHide=!this.navHide
                this.$router.push('/introduce')
                if(this.isTabletCss()){
                    this.enter('0')
                }
            }else{
                this.navHide=false
            }
            if(this.activeName=='second'){
                this.$router.push('/work');
                if(this.isTabletCss()){
                    this.enter('1')
                }
            }else if(this.activeName=='third'){
                this.$router.push('/staff');
                if(this.isTabletCss()){
                    this.enter('2')
                }
            }else if(this.activeName=='fourth'){
                this.$router.push('/enterprise');
                if(this.isTabletCss()){
                    this.enter('3')
                }
            }
        },
        navH5Hide(){
            if(this.navHide){
                let path = this.$route.path;    //或得当前路径
                if(path=='/'){
                    this.navClass='topNav'
                }else{
                    this.navClass='topNav navActive'
                }
            }else{
                this.navClass='topNav navActive'
            }
            this.navHide=!this.navHide
        },
        // 跳转界面
        toPage(to) {
			// console.log(to)
            this.navHide=false
			this.$router.push(to)
		},
        // 滚动事件
        menu() {
            this.scrollNumber = {number: document.documentElement.scrollTop || document.body.scrollTop,path:this.$route.path}
           let scrollNumber=this.scrollNumber.number
            let path = this.$route.path;    //或得当前路径
            // console.log(scrollNumber)
            // 顶部导航样式变化限制
            if(scrollNumber>100&&!this.$look()){
                this.backTop=true
            }else{
                this.backTop=false
            }
            if(scrollNumber!=0){
                this.navClass='topNav navActive'
                if(path=='/'){
                    this.activeName=''
                }
            }else{
                this.navClass='topNav'
                if(path=='/'){
                    this.navClass='topNav'
                }else{
                    this.navClass='topNav navActive'
                }
                this.navHide=false
            }
            // 简介界面滚动定制效果
            if(path=='/introduce'){
                console.log()
                if(scrollNumber>document.querySelector('.introduce .us').offsetTop){
                    this.navCom='navCom'
                }else{
                    this.navCom='navComActive'
                }
                // if(scrollNumber<1000){
                //     this.navtype=0
                // }else if(scrollNumber>99 && scrollNumber<1435){
                //     this.navtype=1
                // }else if(scrollNumber>1435 && scrollNumber<2071){
                //     this.navtype=2
                // }else if(scrollNumber>2071 && scrollNumber<3047){
                //     this.navtype=3
                // }else if(scrollNumber>3047 && scrollNumber<3747){
                //     this.navtype=4
                // }else{
                //     this.navtype=5
                // }
            }else if(path=='/work'){
                // console.log(scrollNumber)
                if(scrollNumber>document.querySelector('.work .tap').offsetTop){
                    this.navCom='navCom'
                }else{
                    this.navCom='navComActive'
                }
            }
        },
         handleChange(val) {
            // console.log(val);
        }
    }
}
</script>
<style lang="less" scoped>
.wenan{
	font-size: 16px;
	@include media-max {
		/*font-size: px2vw(28);*/
		font-size: 0.3rem;
	}
}
@media screen and (max-width:700px ){
	.wenan{
		font-size: 0.3rem;
	}
}
    #navCom{
        /deep/ .topNav{
            top: -200px;
            transition: all 0.3s;
        }
        /deep/.introduceTap{
            position: fixed;
            width: 100%;
            top: 0;
            background-color: #fff;
            transition: all 0.3s;
            z-index: 11;
        }
        /deep/.us{
            padding-top: 140px;
        }
        /deep/.work{
            .tap{
                .el-tabs__header{
                    position: fixed;
                    width: 100%;
                    top: 0;
                    z-index: 121;
                    background: #fff;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                }
                .el-tabs__content{
                    padding-top: 120px;
                }
            }
        } 
            
    }
    #navComActive{
        .topNav{
            top: 0px;
            transition: all 0.3s;
        }
    }
    .navContent{
        // min-width: 1270px;
        .back_Top{
            position: fixed;
            right: 20px;
            bottom: 40px;
            z-index: 111;
            p{
                width: 55px;
                height: 55px;
                background: #fff;
                text-align: center;
                font-size: 12px;
                color: #666666;
                border: 1px solid #eee;
                cursor: pointer;
            }
            p::before{
                content: "";
                width: 26px;
                height: 26px;
                display: block;
                margin: 5px auto 2px;
                background: url(../assets/PC/side-menu.png-v=2.png) no-repeat;
                background-position: -206px 0;
            }
        }
        .navPC{
            display: block;
            .bot2{
                text-align: right;
                line-height: 80px;
                margin-top: -48px;
                font-size: 13px;
            }
        }
        .navH5{
            display: none;
            
        }
        .topNav{
            line-height: 70px;
            height: 70px;
            position: fixed;
            z-index: 99;
            top: 0;
            left: 0;
            width: 100%;
            .main {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
                .H5List{
                    display: none;
                }
    
        
            .logo {
                width: 135px;
                display: block;
                cursor: pointer;
            }

            .nav {
                .nav_tab{
                    ul{
                        display: flex;
                        display: -webkit-flex;
                        justify-content: space-between;
                        flex-direction: row;
                        flex-wrap: wrap;
                        li{
                            cursor: pointer;
                            width: 90px;
                            text-align: center; 
                            font-size: 16px;
                            color: #fff
                        }
                        li:hover{
                            color: #009cff;
                        }
                        li:nth-child(4){
                            text-align: right;
                        }
                        
                    }
                }
                /deep/.el-tabs__header{
                    margin: 0;
                }
                /deep/.el-tabs__item{
                    color: #fff;
                    font-size: 16px;
                    padding: 0 32px;
                }
                // /deep/ .el-tabs__item.is-active{
                //     color: ;
                // }
                /deep/.el-tabs__nav-wrap::after{
                    background-color: transparent;
                }
                /deep/.el-tabs__active-bar{
                    background-color: transparent;
                }
            }

        }
        .topNav.navActive{
            background-color: #fff;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            .nav {
                .nav_tab{
                    li{
                        position: relative;
                        color: #333;
                    }
                    
                    li:hover::after{
                        content: '';
                        position: absolute;
                        width: 32px;
                        height: 3px;
                        background-color: #009cff;
                        bottom: 12px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    li:nth-child(4):hover::after{
                        left: 68%;
                    }
                    li.active{
                        color: #009cff;
                    }
                }

            }
        }
        .navListBGC{
            position: fixed;
            top: 70px;
            width: 100%;
            height: 100%;
            left: 0;
            background-color: rgba(0, 0, 0, 0.2);
        }
        .navList{
            background-color: #fff;
            padding-bottom: 20px;
            // height: 100%;
            .main{
                justify-content:flex-end;
                max-width: 1270px;
                margin: auto;
                overflow: hidden;
            }
            .H5main{
                display: none;
            }
            ul{
                // box-sizing: border-box;
                li{
                    cursor: pointer;
                    box-sizing: border-box;
                    padding-right: 270px;
                    width: 360px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                }
                li.active{
                    color: #009cff;
                }
                li:hover{
                    color: #009cff;
                }
            }
        }
        // 底部样式
        .footer{
            .uss{
                height: 94px;
                line-height: 94px;
                background-color: #009cff;
                background:url(../assets/PC/img_about.png) no-repeat ;
                background-size: 100% 100%;
                /deep/.el-button{
                    background-color: transparent;
                    padding: 0;
                    border:1px solid transparent;
                }
                ul{
                    max-width: 1270px;
                    margin: 0 auto;
                    .span{
                        	@include media-max {
								/*font-size: px2vw(28);*/
								display: none;
							}
                        }
                    li{
                        font-size: 16px;
                        float: left;
                        margin-right: 41px;
                        color: #fff;
                        img{
                            width: 34px;
                            cursor: pointer;
                        }
                        .er_img{
                            display: none;
                        }
                        
                    }
                }
            }

            .footLogo{
                .footCon{
                    display: none;
                }
                .main{
                    position: relative;
                }
                ul{
                    width: 400px;
                    display: inline-block;
                    margin: 63px 0;
                    li{
                        float: left;
                        width: 50%;
                        font-size: 14px;
                        margin-bottom: 23px;
                        color: #666666;
                        cursor: pointer;
                    }
                    li:nth-child(even){
                        margin-right: 0;
                    }
                    li:nth-child(1){
                        cursor: auto;
                        font-size: 16px;
                        margin-bottom: 39px;
                    }
                    li:nth-child(2){
                        cursor: auto;
                        font-size: 16px;
                        margin-bottom: 39px;
                    }
                }
                .foorImg{
                    position: absolute;
                    width: 210px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .foot{
                height: 79px;
                line-height: 79px;
                font-size: 14px;
                color: #666666;
                .main{
                    overflow: hidden;
                    border-top: 1px solid #e6e6e6;
                }
                ul{
                    li{
                        cursor: pointer;
                        float: left;
                        margin-right: 41px;
                    }
                }
                p{
                    float: right;
                }
            }
        }
    }
        // 手机端
        #app.H5Class{
            #navCom{
                 /deep/.work{
                    .tap{
                        .el-tabs__content{
                            padding-top: 1.2rem;
                        }
                    }
                } 
            }
            .navPC{
                display: none;
            }
            .navH5{
                display: block;
            }
            .navContent{
                min-width: 7.5rem;
                height: 100%;
            }
            .navListBGC{
                top: 0.9rem;
            }
            .topNav {
                padding: 0.3rem 0;
                height: 1.2rem;
                line-height: 1;
                .navPC{
                    display: none;
                }
                .navH5{
                    display: block;

                }
                .main{
                    width: 6.8rem;
                    .H5List{
                        display: block;
                        width: 0.4rem;
                    }
                }
                .navList{
                    .main{
                        display: none;
                    }
                    .H5main{
                        display: block;
                        height: 4rem;
                        background-color: #fff;
                        ul{
                            width: 6.8rem;
                            margin: 0 auto;
                            padding-right:0;
                        }
                        li{
                            margin-right: 0.4rem;
                            margin-top: 0.7rem;
                            font-size: 0.3rem;
                            box-sizing: border-box;
                            float: left;
                            width: 2rem;
                            height: 0.8rem;
                            line-height: 0.8rem;
                            border: solid 0.02rem #e6e6e6;
                            border-bottom:0.03rem solid #009cff;
                            padding: 0;
                        }
                        
                        li:nth-child(3){
                            margin-right: 0;
                        }
                    }
                }

                .logo{
                    width: 1.6rem;
                }
                .nav{
                    display: none;
                }
            }

            .footer{
                width: 100%;
                min-width: 100%!important;
                background-color: #f8f8f8;
                min-height: 6rem;
                overflow: hidden;
                .uss{
                    width: 6.8rem;
                    margin: 0 auto;
                    background-color: transparent;
                    background: no-repeat;
                    height: 1.5rem;
                    line-height: 1.5rem;
                    padding: 0;
                    /deep/.el-button{
                        float: left;
                        margin-top: 0.4rem;
                        
                    }
                    ul{
                    	.span{
                        	/*@include media-max {*/
								/*font-size: px2vw(28);*/
								display: none;
							/*}*/
                        }
                        li{
                            color: #666;
                            font-size: 0.3rem;
                            flex-wrap: 700;
                            margin-right: 0.5rem;
                            img{
                                display: none;
                            }
                            .er_img{
                                display: block;
                                width: 0.5rem;
                                height: 0.5rem;
                            }
                        }
                        
                    }
                }
                .footLogo{
                    padding:0;
                    /deep/.el-collapse{
                        border-bottom:0 ;
                        padding-top: 0.3rem;
                    }
                    .main{
                        display: none;
                        
                    }
                    .footCon{
                        display: block;
                        width: 6.8rem;
                        margin: 0 auto;
                        /deep/.el-collapse-item__arrow{
                            transform: rotate(90deg);
                        }
                        /deep/.el-collapse-item__arrow.is-active{
                            transform: rotate(-90deg);
                        }
                        /deep/.el-collapse-item__header{
                            background-color:transparent;
                            font-size: 0.28rem;
                            font-weight: 400;
                            color: #666;
                            // padding: 0.2rem 0;
                            border-bottom:0;
                        }
                        /deep/.el-collapse-item__wrap{
                            background-color: transparent;
                            border-bottom: 1px solid transparent;
                        }
                        /deep/.el-collapse-item__content div{
                            font-size: 0.24rem;
                            color: #999;
                            margin-bottom: 0.3rem;
                        }
                        /deep/.el-collapse-item__content {
                            padding-bottom: 0;
                        }
                    }
                }
                .foot{
                    height: 2.4rem;
                    text-align: center;
                    .main{
                        width: 100%;
                        border-top:0;
                        ul{
                            display: none;
                        }
                    }
                    p{
                        float: inherit;
                        width: 5.9rem;
                        font-size: 0.2rem;
                        color: #999999;
                        margin: 0.4rem auto 0;
                    }
                    .navH5{
                        margin-top: 0.3rem;
                        line-height: 0.4rem;
                        .bot3{
                             line-height: 20px;
                            margin-top: 4px;
                         }
                    }
                }
            }
        }

    @media screen and (max-width:1270px ) {
        #app {
            .navList {
                ul{
                    padding-right: 20px;
                }
            }
            .topNav{
                padding:0 20px;
                box-sizing: border-box;
            }
            .uss{
                padding:0 20px;
                box-sizing: border-box;
            }
            .footLogo{
                padding:0 20px;
                box-sizing: border-box;
            }
        }
    }
</style>